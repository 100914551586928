<template>
  <div class="relative font-sf-pro containDay" :class="classes">
    <div
      class="flex flex-col space-y-1 sm:space-y-3 items-center justify-center"
      :class="!!isActiveStartDate || !!isActiveEndDate ? 'wrapperDateActive' : 'wrapperDate'"
    >
      <p
        class="text-lg sm:text-3xl font-medium"
        :class="[!!isActiveStartDate || !!isActiveEndDate ? '!text-white' : 'text-black']"
      >
        {{ getDate(day) }}
      </p>
      <p
        class="text-xs"
        :class="[
          !!isActiveStartDate || !!isActiveEndDate ? 'text-gray-200' : 'text-gray-300',
          isWeekend && !isActiveStartDate && !isActiveEndDate ? '!text-decorative-scarlet' : ''
        ]"
      >
        {{ getWeekName(day) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  day: {
    type: String,
    default: ''
  },
  selectedStartDate: {
    type: String,
    default: ''
  },
  isLastDayOfMonth: {
    type: Boolean,
    default: false
  },
  selectedEndDate: {
    type: String,
    default: ''
  }
})

const isActiveStartDate = computed(
  () => props.selectedStartDate && props.selectedStartDate === props.day
)

const isActiveEndDate = computed(() => props.selectedEndDate && props.selectedEndDate === props.day)

const inDateRange = computed(
  () =>
    props.selectedStartDate &&
    props.selectedEndDate &&
    props.day > props.selectedStartDate &&
    props.day < props.selectedEndDate
)

const computedDayParams = computed(() => {
  if (props.day) {
    if (isActiveStartDate.value) {
      return 'start-date'
    } else if (isActiveEndDate.value) {
      return 'end-date'
    } else if (inDateRange.value) {
      return 'in-date-range'
    }
  }
  return ''
})

const classes = computed(() => {
  const classesStyle = []
  switch (computedDayParams.value) {
    case 'start-date':
      if (props.isLastDayOfMonth) {
        classesStyle.push('lastDateOfMonth')
      }
      break
    case 'end-date':
      classesStyle.push('endDateRange')
      break

    case 'in-date-range':
      classesStyle.push('inDateRange')
      if (props.isLastDayOfMonth) {
        classesStyle.push('lastDateOfMonth')
      }
      break
    default:
      classesStyle.push('')
      break
  }

  if (props.day === props.selectedStartDate && !!props.selectedEndDate) {
    classesStyle.push('dateRange')
  }

  return classesStyle
})

function getDate (day: number) {
  return new Date(day).getDate()
}

function getWeekName (day: number) {
  const dayOfWeek = new Date(day).getDay()
  const dict: { [key: string]: any } = {
    0: 'Вс',
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб'
  }
  return dict[dayOfWeek]
}

const isWeekend = computed(() => {
  return new Date(props.day).getDay() === 0 || new Date(props.day).getDay() === 6
})
</script>

<style scoped>
.lastDateOfMonth {
  background: #dfe4ef;
  /* width: 43px !important; */
  border-radius: 19px 0px 0px 19px;
  height: 75px !important;
  position: relative;
}

.lastDateOfMonth::after {
  content: '';
  background: #dfe4ef;
  position: absolute;
  height: 100%;
  width: 34px !important;
  top: 0px;
  right: -34px !important;
}

.endDateRange {
  background: #dfe4ef;
  /* margin-right: 6px; */
  border-radius: 0px 19px 19px 0px;
  /* width: 43px; */
  /* height: 75px; */
  position: relative;
}

.wrapperDate {
  box-sizing: border-box;
  padding: 7px 9px;
  color: rgb(0, 0, 0);
  text-align: center;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 100px;
  background: transparent;
  cursor: pointer;
  position: relative;
}

.wrapperDateActive {
  box-sizing: border-box;
  padding: 7px 9px;
  color: rgb(0, 0, 0);
  text-align: center;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 100px;
  background: #0575dd;
  cursor: pointer;
  position: relative;
}

.inDateRange {
  background: #dfe4ef;
  /* margin-right: 6px; */
  border-radius: 0px;
  width: 43px;
  height: 75px;
  position: relative;
}

.inDateRange::after {
  content: '';
  background: #dfe4ef;
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0px;
  right: -8px;
}

.endDateRange::after {
  content: '';
  background: #dfe4ef;
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0px;
  left: -8px;
  z-index: 2;
}

.dateRange {
  background: #dfe4ef;
  /* padding-left: 3px; */
  border-radius: 19px 0px 0px 19px;
  /* width: 43px;
  height: 75px; */
  position: relative;
}

.dateRange::before {
  content: '';
  background: #dfe4ef;
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0px;
  right: -8px;
}

@media screen and (min-width: 640px) {
  .containDay {
    width: 43px;
    height: 75px;
  }

  .lastDateOfMonth {
    width: 43px !important;
    height: 75px !important;
  }

  .endDateRange {
    width: 43px !important;
    height: 75px;
  }

  .dateRange {
    width: 43px;
    height: 75px;
  }
}

@media screen and (max-width: 639px) {
  .containDay {
    width: 38px;
    height: 58px;
  }

  .lastDateOfMonth {
    width: 38px !important;
    height: 58px !important;
  }

  .endDateRange {
    width: 38px !important;
    height: 58px;
  }

  .dateRange {
    width: 38px !important;
    height: 58px;
  }
}
</style>
