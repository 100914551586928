<template>
  <div ref="calendar" class="box-border select-none mx-auto">
    <div class="w-full relative overflow-hidden inline-flex space-x-4 items-center justify-center">
      <Transition name="fade">
        <span v-show="hasPrev" class="hidden sm:block absolute z-50 left-0 top-18">
          <div class="bg-white absolute -top-13 w-12 h-28 z-20" />
          <button data-test="navigationPrev" class="z-40 w-6 h-6 leftArrow" @click="prev">
            <TIconArrowLeft class="w-6 h-6 rotate-180" />
          </button>
        </span>
      </Transition>
      <div
        class="h-30 sm:h-36 transition duration-75 ease-in-out inline-block align-top w-full relative text-left"
        style="padding: 19px 0 3px; margin: 0; transform: translate3d(0px, 0px, 0px)"
      >
        <vue-horizontal
          ref="horizontalTemplateRef"
          :button="false"
          :displacement="0.3"
          @scroll-debounce="onScroll"
        >
          <div
            class="relative transition-transform duration-100 ease-in-out w-75 h-full flex flex-row"
          >
            <div v-for="(data, index) in dateList" :key="index" class="relative !pr-5">
              <div class="relative">
                <span
                  class="font-sans text-sm text-gray-300 z-10"
                  :class="!hasPrev && index === 0 ? 'monthTextDefault' : 'monthText'"
                  :style="[
                    hasPrev
                      ? 'transform: translate3d(22px, 0px, 0px)'
                      : 'transform: translate3d(0px, 0px, 0px)'
                  ]"
                >
                  {{ data.month }}
                </span>
              </div>

              <div class="flex flex-row space-x-2 !pt-1 sm:!pt-2">
                <div
                  v-for="(day, ind) in data.days"
                  :key="ind"
                  class="relative bg-white text-center cursor-pointer inline-block"
                >
                  <t-day
                    :day="day"
                    :is-last-day-of-month="
                      ind === data.days.length - 1 && !!firstChoosedDay && !!secondChoosedDay
                    "
                    :selected-start-date="firstChoosedDay"
                    :selected-end-date="secondChoosedDay"
                    @click="changeChoosedDay(day)"
                  />
                </div>
              </div>
            </div>
          </div>
        </vue-horizontal>
      </div>
      <Transition name="fade">
        <span class="relative w-6 h-6 hidden sm:block">
          <button
            v-show="hasNext"
            data-test="navigationNext"
            class="w-6 h-6 rightArrow"
            @click="next"
          >
            <TIconArrowLeft class="w-6 h-6" />
          </button>
        </span>
      </Transition>
    </div>
  </div>
</template>
<script setup lang="ts">
// @ts-ignore
import VueHorizontal from 'vue-horizontal'
import dayjs from 'dayjs'

const { horizontalTemplateRef, prev, next, hasPrev, hasNext, onScroll } = useVueHorizontalScroll()

const props = defineProps({
  maxDate: {
    type: Date,
    default: null
  },
  startDate: {
    type: String,
    default: null
  },
  endDate: {
    type: String,
    default: null
  },
  countVisibleDay: {
    type: Number,
    default: 100
  }
})

const emit = defineEmits(['selectStartDate', 'selectEndDate'])

type DATE_TYPE = {
  dateFormat: string
  month: string
  timestamp: number
  year: string
}

type MONTH_TYPE = {
  days: string[]
  month: string
  year: string
}

const today = ref(dayjs(new Date()).format('YYYY-MM-DD'))
const firstDay = ref<DATE_TYPE | null>(null)
const visibleDay = ref(props.countVisibleDay)

const firstChoosedDay = ref(props.startDate)

const secondChoosedDay = ref(props.endDate)
const dateList = ref<MONTH_TYPE[] | any[]>([])

watch(firstChoosedDay, () => {
  emit('selectStartDate', firstChoosedDay.value)
})

watch(secondChoosedDay, () => {
  emit('selectEndDate', secondChoosedDay.value)
})

function init () {
  const list = []
  const newList: MONTH_TYPE[] = []

  firstDay.value = formatOneDay(today.value)

  const firstDayTime = firstDay.value.timestamp
  for (let i = 0; i < visibleDay.value; i++) {
    const timestamp = firstDayTime + 1000 * 60 * 60 * 24 * i
    const info = formatOneDay(new Date(timestamp))
    list.push(info)
    let indexElement = 0
    const found = newList.find((element, index) => {
      if (element.month === info.month && element.year === info.year) {
        indexElement = index
        return true
      } else {
        return false
      }
    })

    if (found) {
      newList[indexElement].days.push(info.dateFormat)
    } else {
      newList.push({
        year: info.year,
        month: info.month,
        days: [info.dateFormat]
      })
    }
  }

  dateList.value = newList
}

function changeChoosedDay (datetime: string) {
  if (datetime) {
    if (!firstChoosedDay.value) {
      firstChoosedDay.value = datetime
    } else if (firstChoosedDay.value === datetime && !secondChoosedDay.value) {
      firstChoosedDay.value = ''
      secondChoosedDay.value = ''
    } else if (firstChoosedDay.value === datetime || secondChoosedDay.value === datetime) {
      firstChoosedDay.value = datetime
      secondChoosedDay.value = ''
    } else if (!secondChoosedDay.value) {
      if (firstChoosedDay.value && datetime < firstChoosedDay.value) {
        secondChoosedDay.value = firstChoosedDay.value
        firstChoosedDay.value = datetime
      } else {
        secondChoosedDay.value = datetime
      }
    } else if (
      datetime < firstChoosedDay.value ||
      datetime > firstChoosedDay.value ||
      datetime > firstChoosedDay.value ||
      secondChoosedDay.value > datetime
    ) {
      firstChoosedDay.value = datetime
      secondChoosedDay.value = ''
    }
  }
}

function formatOneDay (day: Date | string) {
  const timestamp = new Date(day).getTime()
  const date = formatDateTime(timestamp) // 2019-06-01
  const dateArray = date.split('-') // [2019,06,01]
  for (const key in dateArray) {
    if (dateArray[key].indexOf('0') === 0) {
      dateArray[key] = dateArray[key].substr(1, 1)
    }
  }

  return {
    dateFormat: date,
    year: dateArray[0],
    month: getMonthName(dateArray[1]),
    timestamp: new Date(date).getTime()
  }
}

function getMonthName (month: string) {
  const dict: { [key: string]: any } = {
    1: 'Январь ',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель ',
    5: 'Май',
    6: 'Июнь ',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь'
  }
  return dict[month]
}

function formatDateTime (timestamp: number) {
  return dayjs(timestamp).format('YYYY-MM-DD')
}

onMounted(() => {
  init()
})
</script>

<style scoped>
.leftArrow {
  display: flex;
  position: relative;
  width: 29px;
  height: 100%;
  top: -11px;
}

.leftArrow::after,
.leftArrow::before {
  content: '';
  width: 64px;
  height: 80px;
  /* margin-top: 10px; */
  top: -13px;
  z-index: 20;
  display: block;
  position: absolute;
  right: -64px;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 38.67%,
    rgba(255, 255, 255, 0) 100%
  );
}

.rightArrow {
  display: flex;
  position: absolute;
  width: 75px;
  height: 100%;
  top: 1px;
}

.rightArrow::after,
.rightArrow::before {
  content: '';
  width: 100%;
  height: 78px;
  /* margin-top: 10px; */
  top: -13px;
  display: block;
  position: absolute;
  left: -72px;
  background: linear-gradient(
    to left,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 38.67%,
    rgba(255, 255, 255, 0) 100%
  );
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.monthTextDefault {
  margin-left: 0px !important;
  position: sticky;
  left: 0px;
  transition: left 0.1s linear 0s;
}

/* .monthText {
  margin-left: 12px !important;
  position: sticky;
  left: 54px;
  transition: left 0.1s linear 0s;
} */

@media screen and (min-width: 640px) {
.monthText {
  margin-left: 12px !important;
  position: sticky;
  left: 54px;
  transition: left 0.1s linear 0s;
}
}

@media screen and (max-width: 639px) {
.monthText {
  margin-left: 0px !important;
  position: sticky;
  left: 0;
  transition: left 0.1s linear 0s;
}
}

</style>
