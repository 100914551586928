// @ts-ignore
import VueHorizontal from 'vue-horizontal'

interface VueHorizontalData {
  left: number
  width: number
  scrollWidth: number
  hasPrev: boolean
  hasNext: boolean
  debounceId?: number | undefined
}

export function useVueHorizontalScroll () {
  const hasPrev = ref(false)
  const hasNext = ref(true)
  const horizontalTemplateRef = ref<InstanceType<typeof VueHorizontal>>()

  return {
    horizontalTemplateRef,
    hasPrev,
    hasNext,
    prev: (event: PointerEvent | MouseEvent) => {
      horizontalTemplateRef.value?.prev(event as PointerEvent)
      if (horizontalTemplateRef.value?.left <= 348) {
        hasPrev.value = false
      }
    },
    next: (event: PointerEvent | MouseEvent) => {
      horizontalTemplateRef.value?.next(event as PointerEvent)
      if (horizontalTemplateRef.value?.left >= 0) {
        hasPrev.value = true
      }
    },
    onScroll (horizontalScroll: VueHorizontalData) {
      hasPrev.value = horizontalScroll.hasPrev
      hasNext.value = horizontalScroll.hasNext
    }
  }
}
