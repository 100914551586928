import type { IEvent } from "@/types"

export function useCalendarApiEventQuery({
  category,
  // limit
}: {
  category?: string
  // limit?: number
}) {
  const route = useRoute()
  const router = useRouter()
  const { $get } = useApi()
  const { formatDate } = useDate()

  const now = new Date(Date.now()).toISOString()
  const DATE_FORMAT = 'YYYY-MM-DD'

  const startDate = ref('')
  const endDate = ref('')
  const limit = ref(6)
  const offset = ref(0)

  if (route.query?.from) {
    startDate.value = route.query.from
  }

  if (route.query?.to) {
    endDate.value = route.query.to
  }

  const link = computed(
    () =>
      `${route.path}?${[
        ['from', startDate.value],
        ['to', endDate.value]
      ]
        .filter((entry) => !!entry[1])
        .map((entry) => entry.join('='))
        .join('&')}`
  )

  watch(startDate, async () => {
    offset.value = 0
    await navigateTo(link.value)
  })

  watch(endDate, async () => {
    offset.value = 0
    await navigateTo(link.value)
  })

  const params = computed<string>(() => {
    let params = []

    params.push(['scheduleInfo.saleOpening_lte', now])
    params.push(['scheduleInfo.saleEnding_gte', now])

    if (category) params.push(['categoryId', category])
    // if (limit) params.push(['_limit', limit])

    if (startDate.value) {
      if (endDate.value) {
        params.push(['scheduleInfo.presentationDate_gte', startDate.value])
        params.push(['scheduleInfo.presentationDate_lte', endDate.value])
      } else {
        params.push(['scheduleInfo.presentationDate', startDate.value])
      }
    }

    params.push(['_sort', 'scheduleInfo.presentationDate:asc'])

    // @ts-ignore
    params = params.map((el) => el.join('='))

    return params.join('&')
  })

  function getNextDay(date: string) {
    const time = new Date(new Date(date))

    return new Date(new Date(time.setDate(time.getDate() + 1)).setMinutes(0)).toISOString()
  }

  async function getEvents() {
    const { data, refresh, execute, error, status, pending } = await useAsyncData<IEvent[]>(
      'get events',
      () =>
        $get(`/api/v1/event/get-the-nearest-events-of-activities-available-for-online?${params.value}&_limit=${limit.value}&_skip=${offset.value}`),
      {
        default: () => [] as IEvent[],
        watch: [startDate, endDate, offset, limit],
      }
    )

    return { data, refresh, execute, error, status, pending }
  }

  async function getTotalEvents() {
    const { data, refresh, execute, error, status, pending } = await useAsyncData<IEvent[]>(
      'get total events',
      () => $get(`/api/v1/event/get/count-nearest-events?${params.value}`),
      {
        default: () => 0,
        watch: [startDate, endDate],
      }
    )
 
    return { data, refresh, execute, error, status, pending }
  }

  return {
    offset,
    limit,
    startDate,
    endDate,
    getEvents,
    getTotalEvents
  }
}
